<template>
  <div class="portfolio"> 
    <div class="cyber">
      <img class="cyber-city" src="@/img/home/CyberCity-Under-Construction.png" alt="CyberKiln Client City under construction image"/>
      <br>
      <div class="city-text">
        <h2>
          We are currently working on an exciting new client feature that is coming very soon. <br><br>Be sure to follow us on social media for updates.
        </h2>
      </div>      
    </div>  
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">

</style>