<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="home"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
  <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-video-cont avoid-clicks">
            <!-- <div style="position:relative;">
              <iframe src="https://player.vimeo.com/video/787847866/006fbfb2eb&amp;background=1&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;badge=1&amp;autopause=0&amp;player_id=&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  title=""></iframe>              
            </div>                   -->

            <!-- <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/787847866?h=006fbfb2eb&amp;badge=0&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Cyber Kiln_Overview_2023_NO GAMES.mp4"></iframe></div> -->

            <video
              src="@/video/CyberKiln_Overview_2022_40MB.mp4"
              :loop="true"
              :autoplay="true"
              :volume="0"  
            />
          </div> 
          <!-- is this needed? -->
          <!-- <img class="wave3" src="../img/waves/Wave5.svg">          -->
        </section>
        <Welcome/>
        <Introduction/>        
        <Portfolio/>
        <Services/>
        <SoftVideo/>       
      </div>
      
      <!-- end content wrap -->
      
      <div id="back-to-top">
        <a href="#top">
          <i class="ui-arrow-up"></i>
        </a>
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>
<script>
import Welcome from '@/components/Welcome.vue'
import Introduction from '@/components/Introduction.vue'
import Portfolio from '@/components/Portfolio.vue'
import Services from '@/components/Services.vue'
import Testimonials from '@/components/Testimonials.vue'
import SoftVideo from "@/components/SoftVideo.vue";

export default{
  components:{
    Welcome,
    Introduction,
    Portfolio,
    Services,
    Testimonials,
    SoftVideo
  }
};

</script>

<style lang="scss">

video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}

</style>